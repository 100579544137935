import { useEffect, useState } from 'react';
import Grid from '@mui/material/Grid';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import Checkbox from '@mui/material/Checkbox';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import ListSubheader from '@mui/material/ListSubheader';

function not(a: readonly { id: string; path: string; width: number; height: number; }[],
  b: readonly { id: string; path: string; width: number; height: number; }[]) {
  return a.filter((value) => b.indexOf(value) === -1);
}

function intersection(a: readonly { id: string; path: string; width: number; height: number; }[],
  b: readonly { id: string; path: string; width: number; height: number; }[]) {
  return a.filter((value) => b.indexOf(value) !== -1);
}

export default function CarouselIndex() {
  const [update, setUpdate] = useState(false);
  const [uploadedImage, setUploadImage] = useState<{ image: string | File }>({ image: "" });
  const [loading, setLoading] = useState(false);
  const [used, setUsed] = useState<{ id: string; path: string; width: number; height: number; }[]>([]);
  const [unUsed, setUnUsed] = useState<{ id: string; path: string; width: number; height: number; }[]>([]);
  const [deleted, setDeleted] = useState<{ id: string; path: string; width: number; height: number; }[]>([]);

  const [checked, setChecked] = useState<readonly { id: string; path: string; width: number; height: number; }[]>([]);

  const leftChecked = intersection(checked, used);
  const middleChecked = intersection(checked, unUsed);
  const rightChecked = intersection(checked, deleted);

  const handleToggle = (item: { id: string; path: string; width: number; height: number; }) => () => {
    const currentIndex = checked.findIndex((image) => image.id === item.id);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(item);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setChecked(newChecked);
  };

  const handleAllLeftToMiddle = () => {
    setUnUsed(unUsed.concat(unUsed));
    setUsed([]);
  };

  const handleCheckedLeftToMiddle = () => {
    setUnUsed(used.concat(leftChecked));
    setUsed(not(used, leftChecked));
    setChecked(not(checked, leftChecked));
  };

  const handleCheckedMiddleToLeft = () => {
    setUsed(used.concat(middleChecked));
    setUnUsed(not(unUsed, middleChecked));
    setChecked(not(checked, middleChecked));
  };

  const handleAllMiddleToLeft = () => {
    setUsed(used.concat(unUsed));
    setUnUsed([]);
  };

  const handleAllMiddleToLRight = () => {
    setDeleted(deleted.concat(unUsed));
    setUnUsed([]);
  };

  const handleCheckedMiddleToRight = () => {
    setDeleted(deleted.concat(middleChecked));
    setUnUsed(not(unUsed, middleChecked));
    setChecked(not(checked, middleChecked));
  };

  const handleAllRightToMiddle = () => {
    setUnUsed(unUsed.concat(deleted));
    setDeleted([]);
  };

  const handleCheckedRightToMiddle = () => {
    setUnUsed(unUsed.concat(rightChecked));
    setDeleted(not(deleted, rightChecked));
    setChecked(not(checked, rightChecked));
  };

  const customList = (title: string, items: readonly { id: string; path: string; width: number; height: number; }[]) => (
    <Paper sx={{ width: 500, maxHeight: "75vh", overflow: 'auto' }}>
      <ListSubheader>{title}</ListSubheader>
      <List dense component="div" role="list">
        {items.map((image, i) => {
          // const labelId = `transfer-list-item-${value}-label`;
          return (
            <ListItem
              key={i}
              role="listitem"
              button
              onClick={handleToggle(image)}
            >
              <ListItemIcon>
                <Checkbox
                  checked={checked.findIndex((c) => c.id === image.id) !== -1}
                  tabIndex={-1} disableRipple
                  inputProps={{ 'aria-labelledby': image.id, }}
                />
              </ListItemIcon>
              <div style={{ width: "400px", height: "100px" }}>
                <img src={image.path} width={image.width} height={image.height} alt="" style={{ width: "100%", height: "100%" }} />
              </div>
            </ListItem>
          );
        })}
      </List>
    </Paper>
  );

  async function onClickUpdateCarousel() {
    setLoading(true);
    await fetch(`${process.env.NODE_ENV === 'development' ? process.env.REACT_APP_BACKEND_URL :
      ''}/api/admin/carousel/used/${JSON.stringify(used.map((u) => u.id))}`, { method: "PUT" });
    await fetch(`${process.env.NODE_ENV === 'development' ? process.env.REACT_APP_BACKEND_URL :
      ''}/api/admin/carousel/un-used/${JSON.stringify(unUsed.map((u) => u.id))}`, { method: "PUT" });
    if (deleted.length > 0) {
      await fetch(`${process.env.NODE_ENV === 'development' ? process.env.REACT_APP_BACKEND_URL :
        ''}/api/admin/carousel/del/${JSON.stringify(deleted.map((u) => u.id))}`, { method: "DELETE" });
    };
    setLoading(false);
    setUpdate(!update);
  };

  async function uploadImage() {
    if (uploadedImage.image && uploadedImage.image !== "") {
      const formData = new FormData();
      formData.append('image', uploadedImage.image)
      const result = await fetch(`${process.env.NODE_ENV === 'development' ? process.env.REACT_APP_BACKEND_URL :
        ''}/api/admin/carousel`, { method: "POST", body: formData });
      const json = await result.json();
      if (json.result === 'success') { setUploadImage({ image: "" }) }
    };
    setUpdate(!update);
  };

  useEffect(() => {
    setLoading(true);
    ; (async () => {
      const result = await fetch(`${process.env.NODE_ENV === 'development' ? process.env.REACT_APP_BACKEND_URL : ''}/api/admin/carousel`);
      const json = await result.json(); setUnUsed(json.notUseList); setUsed(json.inuseList);
    })()
    setLoading(false);
  }, [update]);
  if (loading) {
    return (
      <div>Loading</div>
    )
  } else
    return (<>
      <Grid container spacing={2} justifyContent="center" >
        <Grid item>{customList("正在使用", used)}</Grid>
        <Grid item display="flex" alignItems="center">
          <Grid container direction="column" alignItems="center">
            <Button
              sx={{ my: 0.5 }}
              variant="outlined"
              size="small"
              onClick={handleAllLeftToMiddle}
              disabled={used.length === 0}
              aria-label="move all right"
            >
              ≫
            </Button>
            <Button
              sx={{ my: 0.5 }}
              variant="outlined"
              size="small"
              onClick={handleCheckedLeftToMiddle}
              disabled={leftChecked.length === 0}
              aria-label="move selected right"
            >
              &gt;
            </Button>
            <Button
              sx={{ my: 0.5 }}
              variant="outlined"
              size="small"
              onClick={handleCheckedMiddleToLeft}
              disabled={middleChecked.length === 0}
              aria-label="move selected left"
            >
              &lt;
            </Button>
            <Button
              sx={{ my: 0.5 }}
              variant="outlined"
              size="small"
              onClick={handleAllMiddleToLeft}
              disabled={unUsed.length === 0}
              aria-label="move all left"
            >
              ≪
            </Button>
          </Grid>
        </Grid>
        <Grid item>{customList("未使用", unUsed)}</Grid>
        <Grid item display="flex" alignItems="center">
          <Grid container direction="column" alignItems="center">
            <Button
              sx={{ my: 0.5 }}
              variant="outlined"
              size="small"
              onClick={handleAllMiddleToLRight}
              disabled={unUsed.length === 0}
              aria-label="move all right"
            >
              ≫
            </Button>
            <Button
              sx={{ my: 0.5 }}
              variant="outlined"
              size="small"
              onClick={handleCheckedMiddleToRight}
              disabled={middleChecked.length === 0}
              aria-label="move selected right"
            >
              &gt;
            </Button>
            <Button
              sx={{ my: 0.5 }}
              variant="outlined"
              size="small"
              onClick={handleCheckedRightToMiddle}
              disabled={rightChecked.length === 0}
              aria-label="move selected left"
            >
              &lt;
            </Button>
            <Button
              sx={{ my: 0.5 }}
              variant="outlined"
              size="small"
              onClick={handleAllRightToMiddle}
              disabled={deleted.length === 0}
              aria-label="move all left"
            >
              ≪
            </Button>
          </Grid>
        </Grid>
      </Grid>
      <Box><Button onClick={async () => await onClickUpdateCarousel()}>{'提交'}</Button></Box>
      <div>
        <Box sx={{ marginTop: 2 }}>
          <label htmlFor="video">{"短片："}</label>
          <input type="file" name="video" id="video" onChange={(e) => {
            if (e.currentTarget.files?.length) { setUploadImage({ image: e.currentTarget.files[0] }) }
          }} />
        </Box>
        <Box><Button onClick={async () => uploadImage()}>上傳圖片</Button></Box>
      </div>
    </>
    );
};